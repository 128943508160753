import React, {FC, MouseEvent, useState} from "react";
import * as s from "./Form-user-password-styles";
import {FormikProps} from "formik";
import {InitialValuesFormikRegistrationType} from "../../../pages";
import {Animation, FieldCheckbox} from "../../../shared";
import {ButtonProperty} from "../../../shared/config";

type FormUserPasswordType = {
  className?: string;
  formik: FormikProps<InitialValuesFormikRegistrationType>;
};

export const FormUserPassword: FC<FormUserPasswordType> = ({
                                                             className,
                                                             formik,
                                                           }) => {
  const [isWarning, setWarning] = useState(false);

  const onChangeIsWarning = () => {
    if (formik.isValid) {
      setWarning(false);
    } else {
      setWarning(true);
    }
  };

  const onChangeValueInput =
    (formikValueName: string, formikValue: boolean) =>
      (e: MouseEvent<HTMLButtonElement>) => {
        const element = e.target as HTMLElement;
        if (element.nodeName !== "A") {
          formik.setFieldValue(formikValueName, !formikValue);
        }
      };

  return (
    <s.FormUserPassword className={className} onSubmit={formik.handleSubmit}>
      <s.ListCheckboxs>
        <s.ItemCheckbox>
          <FieldCheckbox
            checked={formik.values.personal_data_handling}
            error={
              formik.touched["personal_data_handling"]
                ? formik.errors.personal_data_handling
                : ""
            }
            label={
              <s.Label>
                Я ознакомился с{" "}
                <s.Link
                  href={
                    "https://www.mars.com/global/policies/privacy/pp-russian"
                  }
                  target={"_blank"}
                >
                  Положением о конфиденциальности,{" "}
                </s.Link>
                <s.Link
                  href={
                    "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Правила_акции_Здоровый_питомец_24.pdf"
                  }
                  target={"_blank"}
                >
                  Правилами Акции{" "}
                </s.Link>
                и даю свое согласие на{" "}
                <s.Link
                  href={"https://www.mars.com/privacy-policy-russia"}
                  target={"_blank"}
                >
                  обработку персональных данных.*
                </s.Link>
              </s.Label>
            }
            onClickInputChecked={onChangeValueInput(
              "personal_data_handling",
              formik.values.personal_data_handling
            )}
          />
        </s.ItemCheckbox>

        <s.ItemCheckbox>
          <FieldCheckbox
            checked={formik.values.age_confirmation}
            error={
              formik.touched["age_confirmation"]
                ? formik.errors.age_confirmation
                : ""
            }
            label={
              <s.Label>Я подтверждаю, что мне исполнилось 18 лет.*</s.Label>
            }
            onClickInputChecked={onChangeValueInput(
              "age_confirmation",
              formik.values.age_confirmation
            )}
          />
        </s.ItemCheckbox>

        <s.ItemCheckbox>
          <FieldCheckbox
            checked={formik.values.promotional_mailing}
            label={
              <s.Label>
                Я даю согласие на{" "}
                <s.Link
                  href={
                    "https://xn----ctbfggjf0anaeki8a4dxd.xn--p1ai/media/rules/Согласие_на_получение_новостной_и_рекламной_рассылки.pdf"
                  }
                  target={"_blank"}
                >
                  получение рекламной рассылки{" "}
                </s.Link>
                (в т.ч. в виде смс, электронных писем и/или через мессенджеры).
              </s.Label>
            }
            onClickInputChecked={onChangeValueInput(
              "promotional_mailing",
              formik.values.promotional_mailing
            )}
          />
        </s.ItemCheckbox>

        <s.ItemCheckbox>
          <FieldCheckbox
            checked={formik.values.loyalty_card}
            label={<s.Label>У меня есть карта лояльности Зоозавра</s.Label>}
            onClickInputChecked={onChangeValueInput(
              "loyalty_card",
              formik.values.loyalty_card
            )}
          />
        </s.ItemCheckbox>
      </s.ListCheckboxs>

      <s.Text>*Обязательные для заполнения</s.Text>

      <s.WrapButton>
        <Animation isShow={isWarning}>
          <s.Warning>Пожалуйста, заполните все обязательные поля</s.Warning>
        </Animation>

        <s.ButtonRegistration
          property={ButtonProperty.SECONDARY}
          text={"Зарегистрироваться"}
          type={"submit"}
          onClickButton={onChangeIsWarning}
        />

        <s.TextWrapButton>
          Нажимая кнопку “Зарегистрироваться”, вы соглашаетесь с нашей{" "}
          <s.LinkWrapButton
            href={"https://www.mars.com/global/policies/privacy/pp-russian"}
            target={"_blank"}
          >
            политикой конфиденциальности
          </s.LinkWrapButton>
        </s.TextWrapButton>
      </s.WrapButton>
    </s.FormUserPassword>
  );
};
